import type { History } from 'history';
import { createBrowserHistory } from 'history';
import { cloneDeep, isEqual } from 'lodash';
import type { Store } from 'redux';

import { appRoutes } from '@maggie/app/constants/app-routes';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { Tracker } from '@maggie/core/tracker/tracker';
import { AppLayoutSelectors } from '@maggie/layout/selectors';
import { SplitViewLayoutUtils } from '@maggie/layout/view/route-split-view-layout/utils';
import { NavigationActions } from '@maggie/store/navigation/actions';
import { NavigationSelectors } from '@maggie/store/navigation/selectors';
import type { RouteType } from '@maggie/store/navigation/utils';
import { findParentRoute, getLocationPath } from '@maggie/store/navigation/utils';
import { SplitViewLayoutActions } from '@maggie/store/split-view-layout/actions';
import { SplitViewLayoutSelectors } from '@maggie/store/split-view-layout/selectors';
import type { SplitDetailState } from '@maggie/store/split-view-layout/types';
import type { LxStoreState } from '@maggie/store/types';

import { HashManager } from './hash-manager';
import { routePaths } from './routes';
import type { TrackedView } from './types';
import type { RouteName, RouteParams } from './types';

const matchRoute = (route?: RouteType, splitViewRoute?: SplitDetailState<RouteName>): boolean => {
  const copyRoute = cloneDeep(route);
  delete copyRoute?.params['query'];

  return (
    !!splitViewRoute &&
    !!copyRoute &&
    copyRoute.route === splitViewRoute.routeName &&
    isEqual(copyRoute.params, splitViewRoute.params)
  );
};

type NavigateOptions = {
  /**
   * it replaces browser url (not pushing to history)
   *
   * @default false
   */
  replace?: boolean;

  /**
   * does not update history at all (does not touch browser url)
   *
   * @default false
   */
  skipHistoryUpdate?: boolean;
};

export class NewAppRouter {
  private history: History;
  private store: Store<LxStoreState>;
  public hashManager: HashManager;
  private trackedView: TrackedView;
  private lastRouteUrl: string;

  constructor(store: Store<LxStoreState>, basename?: string) {
    const historyBasename = basename ? `${basename}/learn` : undefined;
    this.history = createBrowserHistory({ basename: historyBasename });
    this.store = store;
    this.hashManager = new HashManager(routePaths, historyBasename);

    this.history.listen(location => {
      /**
       * (SC Training Only) we only need to update last route if training is in the URL,
       *    this will allow the listener in MemoizedAppRouterProvider to ignore the route changes for training routes
       */
      if (location.pathname.includes(`${basename}/`)) {
        this.lastRouteUrl = getLocationPath(location);
      }
    });
  }

  public getHistory() {
    return this.history;
  }

  public findMatch(hash: string) {
    return this.hashManager.findMatch(hash);
  }

  public getLastRouteUrl() {
    return this.lastRouteUrl;
  }

  /**
   * Get current main route based on window.location
   */
  public getMain() {
    const url = getLocationPath();
    const match = window.__router.hashManager.findMatch(url);
    if (!match) {
      const fallbackRoute = NavigationSelectors.getFallbackRoute(this.store.getState());
      return { routeName: fallbackRoute, params: {} };
    }

    // For main: route & params - are based on current location (stored in URL)
    return { routeName: match.routeName, params: match.params };
  }

  public getDetail() {
    return SplitViewLayoutSelectors.getCurrentSplitViewDetail(this.store.getState());
  }

  /**
   * Checks
   *  * If restrictToLessonScreen is not present
   *  * If route is part of split view layout
   *  * If current viewport size is acceptable on split view layout
   */
  public isSplitViewLayout(routeName: RouteName) {
    const isMobile = AppLayoutSelectors.getIsMobile(undefined, routeName);
    const routeLayout = appRoutes[routeName].splitViewLayout;
    const restrictedLesson =
      routeName === 'lesson' ? !!this.store.getState().config.restrictToLessonScreen : false;

    return !isMobile && !!routeLayout && !restrictedLesson;
  }

  /**
   * Triggers the router to figure out which route to go to.
   * It notifies in case the hash has been changed, without the `navigate` method.
   *
   * For example:
   * User opens a new tab with the route `web.edapp.com/#sign-up`. It won't really call navigate,
   * unless we "trigger" router to figure out where to go.
   *
   */
  public trigger(hash?: string, replace = true) {
    const m = hash ? this.hashManager.findMatch(hash) : undefined;
    if (!!m) {
      this.navigate(m.routeName, m.params, replace);
    } else {
      // TODO: https://safetyculture.atlassian.net/browse/TRAINING-527
      this.store.dispatch(NavigationActions.fallbackDefaultRoute());
    }
  }

  /**
   * https://ed-app.atlassian.net/wiki/spaces/DEV/pages/2039709703/Split+View+Routing
   *
   * @param routeName: The name of the route
   * @param params: The parameters of the route
   * @param replace: If the route should be pushed or replaced in browser history
   * @param forceNavigation: Option to skip any logic and navigate straight away
   */
  public navigate<T extends RouteName>(
    routeName: T,
    params: RouteParams<T>,
    replace?: boolean,
    forceNavigation?: boolean
  ) {
    if (forceNavigation || !this.isSplitViewLayout(routeName)) {
      this.doNavigate(routeName, params, { replace });
      return;
    }

    const parentSide = this.getSplitViewParentSide(routeName);
    switch (parentSide) {
      case 'detail': {
        const currentDetailRoute = this.getDetail();
        if (!currentDetailRoute) {
          throw Error(`Clicked on a child of detail but there is no current detail!? ${routeName}`);
        }
        // 1. move route to detail
        this.store.dispatch(SplitViewLayoutActions.setDetail(routeName, params));
        this.doNavigate(routeName, params, { skipHistoryUpdate: true });
        // 2. move detail to main or set the right parent to main
        const parent = findParentRoute(routeName, params, this.store.getState());
        if (!parent || matchRoute(parent, currentDetailRoute)) {
          this.doNavigate(currentDetailRoute.routeName, currentDetailRoute.params, {});
        } else {
          this.doNavigate(parent!.route, parent!.params, {});
        }
        break;
      }

      case 'main': {
        this.store.dispatch(SplitViewLayoutActions.setDetail(routeName, params));

        // if the current view is not in split view layout but the parent of the "moving to view" is
        // we need to change browser history
        const currentRoute = this.hashManager.findMatch(getLocationPath());
        const skipHistoryUpdate = !!currentRoute
          ? !!appRoutes[currentRoute.routeName].splitViewLayout
          : true;

        this.doNavigate(routeName, params, { skipHistoryUpdate });
        break;
      }

      default: {
        this.store.dispatch(SplitViewLayoutActions.clearDetail());

        // parent is not in screen - deeplinks, going back, etc
        const hasChild = SplitViewLayoutUtils.hasChild(routeName);
        if (hasChild) {
          this.doNavigate(routeName, params, { replace });
          break;
        }

        const parent = findParentRoute(routeName, params, this.store.getState());
        if (!parent) {
          // no parent - just go to the route itself
          this.doNavigate(routeName, params, { replace });
        } else {
          // go to parent
          this.doNavigate(parent.route, parent.params, { replace: true });
        }
        break;
      }
    }
  }

  public goBack() {
    const currentRoute = this.getMain();
    this.store.dispatch(NavigationActions.goBack(currentRoute.routeName, currentRoute.params));
  }

  private doNavigate<T extends RouteName>(
    routeName: T,
    params: RouteParams<T>,
    { replace, skipHistoryUpdate = false }: NavigateOptions
  ) {
    const routeUrl = routePaths[routeName].create(params as any);
    if (this.lastRouteUrl === routeUrl) {
      return; // already there - nothing to do
    }

    // 1. update history
    if (!skipHistoryUpdate) {
      // Update last route
      this.lastRouteUrl = routeUrl;

      if (replace) {
        this.history.replace(routeUrl);
      } else {
        this.history.push(routeUrl);
      }

      // notify host about location_change (UXP)
      HostedWebviewUtils.triggerLocationChange(routeUrl);
    }

    // 2. trigger side-effects
    this.store.dispatch(NavigationActions.didNavigateRoute(routeName, params));

    // 3. End interaction
    this.trackedView?.end?.();
    if (!this.isSplitViewLayout(routeName)) {
      this.trackedView = Tracker.trackView({ type: 'view', name: routeName });
    } else {
      this.trackedView = undefined;
    }
  }

  /**
   * Finds the parent of the route passed as argument and check if it's in the main or detail.
   * If not found, it returns null
   */
  private getSplitViewParentSide(routeName: RouteName): 'main' | 'detail' | null {
    const routeParents = appRoutes[routeName].splitViewLayout?.parents;
    if (!routeParents) {
      throw Error(`Route doesn't support splitViewLayout ${routeName}`);
    }

    const currentMainRoute = this.getMain();
    if (routeParents.includes(currentMainRoute.routeName)) {
      return 'main';
    }

    const currentDetailRoute = this.getDetail();
    if (currentDetailRoute && routeParents.includes(currentDetailRoute.routeName)) {
      return 'detail';
    }

    return null;
  }
}
